import {createRouter, createWebHashHistory} from 'vue-router';

import {useAuthStore} from "@/stores/auth.store";
import {EPermissaoSistema} from "@/stores/enums/EPermissaoSistema";
import AppLayout from "@/layout/AppLayout.vue";
import {useHomeStore} from "@/stores/home.store";


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/signin",
            name: "signin",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageSignin.vue')
        },
        {
            path: "/usuario/recuperacao-senha/:solicitacaoId",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageRecuperacaoSenha.vue')
        },
        {
            path: "/teste",
            meta: {authRequired: true},
            component: () => import('@/views/pages/teste/Teste.vue')
        },
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'home',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/home/PageHome.vue')
                },
                {
                    path: '/perfil',
                    name: 'perfil',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/perfil/PagePerfil.vue')
                },
                {
                    path: '/chamados',
                    name: 'chamados',
                    meta: {permissao: EPermissaoSistema.CHAMADO, authRequired: true},
                    component: () => import('@/views/pages/chamado/PageChamados.vue')

                },
                {
                    path: '/empresas',
                    name: 'empresas',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/empresa/PageEmpresas.vue')

                },
                {
                    path: '/avisos',
                    name: 'avisos',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/aviso/PageAvisos.vue')

                },

                {
                    path: '/empresa/newsletter',
                    name: 'empresa_newsletter',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/empresa/PageNewsletter.vue')
                },
                {
                    path: '/usuarios',
                    name: 'usuarios',
                    meta: {permissao: EPermissaoSistema.USUARIO_GERENCIAR, authRequired: true},
                    component: () => import('@/views/pages/usuario/PageUsuarios.vue')
                },

                {
                    path: '/contratos',
                    name: 'contratos',
                    meta: {permissao: EPermissaoSistema.CONTRATO, authRequired: true},
                    component: () => import('@/views/pages/contrato/PageContratos.vue')

                },

                {
                    path: '/assuntos',
                    name: 'assuntos',
                    meta: {permissao: EPermissaoSistema.ASSUNTO, authRequired: true},
                    component: () => import('@/views/pages/assunto/PageAssuntos.vue')
                },

                {
                    path: '/permissao_negada',
                    name: 'permissao_negada',
                    component: () => import("@/layout/layoutPages/PagePermissaoNegada.vue")

                },


            ]
        },


    ]
});

router.beforeEach(async (to, from, next) => {

    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = true;
    const authStore = useAuthStore();
    const authRequired = (!to.meta ? true : to.meta.authRequired);


    //Verifica se a rota precisa de autenticação
    if (!authRequired) {
        //Se não precisar, redireciona para a rota solicitada
        return next();

    } else {

        //A partir daqui, a rota precisa de autorização
        //Verifica se o usuário está logado através da store isLoggedIn e Permissoes

        if (!(authStore.isLoggedIn() && authStore.usuarioPermissoes)) {
            //Nao esta logado ou não tem permissao

            //Se não tiver logado direciona para o login
            if (!authStore.isLoggedIn()) {
                authStore.sair();
                next('/signin')
            } else {

                //Aciona o método que verifica se o usuário está ativo, por meio da sessão ou token válido
                if (!(await authStore.verificarUsuarioAtivo())) {
                    //Se não conseguiu verificar o usuario ou usuario não ativo, direciona para o login
                    authStore.sair();
                    next('/signin')
                }
            }

        }
        //fim da verificação do usuario logado

        //Verifica se a rota precisa de permissão
        if (to.meta.permissao) {
            //precisa
            //Verifica se o usuário possui a permissão solicitada pela rota
            if (authStore.temPermissao(to.meta.permissao as EPermissaoSistema)) {
                //tem = continua para rota solicitada
                return next();

            } else {
                //não tem = direciona para pagina de acesso negado
                return next('/permissao_negada');
            }
        } else {
            //não precisa = continua para rota solicitada
            return next();
        }


    }

});

router.afterEach(() => {

    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = false;
})


export default router;
