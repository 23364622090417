import {useRequestHelper} from "@/helpers/request.helper";
import type {LoginCommand} from "@/commands/auth.commands";
import type {EncerrarUsuarioSessaoCommand, VerificarUsuarioSessaoCommand} from "@/commands/usuario.commands";
import {useAuthStore} from "@/stores/auth.store";
import type {UsuarioInternoVm} from "@/type/usuario.type";
import type {UsuarioInternoLoginVm} from "@/type/auth.type";



const requestHelper = useRequestHelper();



class AuthService {

    login(userCommand: LoginCommand) {
        return requestHelper.sendRequestPost<UsuarioInternoLoginVm>('usuario/interno/login', userCommand, false);
    }

    verificarSessao(command: VerificarUsuarioSessaoCommand) {
        return requestHelper.sendRequestPost<UsuarioInternoLoginVm>('usuario/interno/sessao/verificar', command, false);
    }

    verificarUsuarioAutenticado() {
        return requestHelper.sendRequestGet<UsuarioInternoVm>('usuario/interno/info');
    }



}

export default new AuthService();