import {type Ref, ref} from "vue";
import {defineStore} from "pinia";

import AuthService from "@/services/auth.service";
import type {LoginCommand} from "@/commands/auth.commands";
import type {EPermissaoSistema} from "@/stores/enums/EPermissaoSistema";
import {EStatusUsuario, type UsuarioInternoStoreVm} from "@/type/usuario.type";
import {useToastHelper} from "@/helpers/toast.helper";
import type {UsuarioInternoLoginVm} from "@/type/auth.type";
import UsuarioService from "@/services/usuario.service";


const userStorage = localStorage.getItem('user');
const userTokenStorage = localStorage.getItem('userToken');
const userSessionStorage = localStorage.getItem('userSessao');

const deleteTempKeys = () => {

    // Obtém todas as chaves do localStorage
    const keys = Object.keys(localStorage);

    // Itera sobre as chaves e remove aquelas que começam com "temp"
    keys.forEach((chave) => {
        if (chave.startsWith("temp")) {
            localStorage.removeItem(chave);
        }
    });
}

const deleteUserStorages = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userToken');
    localStorage.removeItem('userSession');
    localStorage.removeItem('userTemp');

}


export const useAuthStore = defineStore('authStore', () => {

    const {showRequestError} = useToastHelper();

    const user = ref(userStorage);
    const userStore = ref<UsuarioInternoStoreVm>({
        publicId: "",
        nome: "",
        email: "",
        imagem: "",
        status: 3,
        sessao: ""
    });
    const userToken = ref(userTokenStorage);
    const userSession = ref(userSessionStorage);
    const usuarioPermissoes = ref();


    function entrar(infoLogin: LoginCommand) {

        deleteUserStorages();
        deleteTempKeys();

        return AuthService.login(infoLogin)
            .then((ret) => {

                //adiciona informação do usuario na store e storage
                setUserInfo(ret.data);

                return Promise.resolve(true);

            })
            .catch((error) => {
                return Promise.reject(error);
            });


    }

    function sair() {
                //Verifica se tem sessão ativa
        if (isUserSessionExists()) {

            UsuarioService.encerrarUsuarioSessao({
                encerrarTodasSessoes: false,
                sessao: getUserSession() as string
            });
        }

        deleteTempKeys();
        deleteUserStorages();
        usuarioPermissoes.value = null;


    }

    function isLoggedIn() {
        return !!localStorage.getItem('user');
    }

    function setUserInfo(infoUser: UsuarioInternoLoginVm): void {
        //Preenche a store com informacoes do usuario

        userStore.value = {
            publicId: infoUser.publicId,
            nome: infoUser.nome,
            email: infoUser.email,
            imagem: infoUser.imagem,
            status: infoUser.statusUsuario,
            sessao: infoUser.sessao
        };

        user.value = JSON.stringify(userStore.value);

        localStorage.setItem('user', user.value);

        //Obtem o Token JWT
        if (infoUser.token) {
            setUserToken(infoUser.token);
        }

        //Obtem a sessão
        if (infoUser.sessao) {
            setUserSession(infoUser.sessao)
        }

        //Adiciona as permissoes na store SOMENTE
        setPermissoes(infoUser.permissoes);
    }

    function getUser() {
        return localStorage.getItem('user');
    }

    function setUserToken(tokenJwt: string) {
        userToken.value = tokenJwt;
        localStorage.setItem('userToken', tokenJwt);
    }

    function isUserTokenExists() {
        return !!localStorage.getItem('userToken');
    }

    function getUserToken() {
        return localStorage.getItem('userToken');
    }

    function setUserSession(sessao: string) {
        userSession.value = sessao;
        localStorage.setItem('userSession', sessao);
    }

    function isUserSessionExists() {
        return !!localStorage.getItem('userSession');
    }

    function getUserSession() {
        return localStorage.getItem('userSession');
    }

    function atualizarStorage() {
        user.value = JSON.stringify(userStore.value);
        localStorage.setItem('user', JSON.stringify(user.value));
    }

    function alterarNome(novoNome: string) {
        userStore.value.nome = novoNome;
        atualizarStorage();

    }

    function alterarEmail(novoEmail: string) {
        userStore.value.email = novoEmail;
        userStore.value.status = EStatusUsuario.Cadastrado;

        atualizarStorage();
    }

    function alterarStatus(novoStatus: EStatusUsuario) {
        userStore.value.status = novoStatus;
        atualizarStorage();
    }


    function setPermissoes(permissoes: any) {
        usuarioPermissoes.value = permissoes;
    }

    function temPermissao(permissao: EPermissaoSistema) {

        try {
            return !!usuarioPermissoes.value.includes(permissao);

        } catch (e) {
            return false;
        }

    }

    function verificarUsuarioAtivo() {
        return new Promise<boolean>((resolve) => {

            //Verifica se possui sessão salva na maquina
            if (isUserSessionExists()) {
                //Valida a sessão do usuario
                AuthService.verificarSessao({sessao: getUserSession() as string})
                    .then((ret) => {

                        //Atualiza as informações na store e storage
                        setUserInfo(ret.data)

                        resolve(true);
                    })
                    .catch((err) => {
                        showRequestError(err);
                        resolve(false);
                    });

            } else {
                //Verifica através do Token
                // realiza uma consulta no backend das informacoes do usuario autenticado
                AuthService.verificarUsuarioAutenticado()
                    .then((ret) => {

                        //const tokenUsr = getUserToken() as string;

                        deleteTempKeys();
                        deleteUserStorages();

                        setUserInfo({
                            token: userToken.value,
                            publicId: ret.data.publicId,
                            nome: ret.data.nomeCompleto,
                            email: ret.data.email,
                            imagem: ret.data.imagem,
                            statusUsuario: ret.data.status,
                            statusUsuarioDescricao: ret.data.statusDescricao,
                            permissoes: ret.data.permissoes.map(p => p.codigo.toString().padStart(4, "0")),
                            sessao: ""
                        })

                        resolve(true);

                    })
                    .catch((err) => {
                        showRequestError(err);
                        resolve(false);
                    })

            }


        })
    }

    return {
        entrar,
        sair,
        userStore,
        userToken,
        isLoggedIn,
        usuarioPermissoes,
        temPermissao,
        alterarNome,
        alterarEmail,
        alterarStatus,
        verificarUsuarioAtivo,
        isUserSessionExists,
        getUserSession

    }
});



