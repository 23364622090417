import {useRequestHelper} from "@/helpers/request.helper";
import type {UsuarioInternoVm} from "@/type/usuario.type";
import type {
    AlterarDispositivoUsuarioSessaoCommand,
    AlterarEmailUsuarioCommand,
    AlterarInfoUsuarioCommand, AlterarInfoUsuarioInternoCommand,
    AlterarSenhaUsuarioCommand, AtivarUsuarioCommand,
    CadastrarUsuarioInternoCommand,
    ConfirmarEmailCommand, DesativarUsuarioCommand, EncerrarUsuarioSessaoCommand,
    RecuperarSenhaUsaurioCommand,
    SolicitarRecuperacaoUsuarioCommand, VerificarUsuarioSessaoCommand
} from "@/commands/usuario.commands";
import type {ConsultarUsuarioInternoQuery} from "@/queries/usuario.query";
import {object} from "zod";

const requestHelper = useRequestHelper();


class UsuarioService {

    consultar() {
        return requestHelper.sendRequestGet<UsuarioInternoVm>('usuario/interno/info');
    }

    consultarUsuarioInterno(query: ConsultarUsuarioInternoQuery) {
        return requestHelper.sendRequestPost<UsuarioInternoVm>('usuario/interno/consultar', query);
    }

    gravar(command: CadastrarUsuarioInternoCommand) {
        return requestHelper.sendRequestPost<UsuarioInternoVm>('usuario/interno/cadastrar', command);
    }

    ativarUsuario(command: AtivarUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/interno/ativarUsuario',command);
    }

    desativarUsuario(command: DesativarUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/interno/desativarUsuario',command);
    }


    listarUsuariosInternos() {
        return requestHelper.sendRequestPost<any>('usuario/interno/listar', {});
    }

    alterarInfoUsuario(command: AlterarInfoUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/informacoes', command);
    }

    alterarInfoUsuarioInterno(command: AlterarInfoUsuarioInternoCommand) {
        return requestHelper.sendRequestPost<object>('usuario/interno/alterar', command);
    }


    alterarSenha(command: AlterarSenhaUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/senha', command);
    }


    alterarEmail(command: AlterarEmailUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/email', command);
    }


    solicitarCodigoConfirmacao() {
        return requestHelper.sendRequestPost<object>('usuario/solicitar/confirmacao', {});
    }

    confirmarEmail(command: ConfirmarEmailCommand) {
        return requestHelper.sendRequestPost<object>('usuario/confirmar', command);
    }

    solicitarRecuperacaoSenha(command: SolicitarRecuperacaoUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/solicitar/recuperacao', command, false);

    }

    recuperarSenha(command: RecuperarSenhaUsaurioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/recuperar-senha', command, false);
    }

    listarUsuarioSessoes(){
        return requestHelper.sendRequestPost<any>('usuario/interno/sessao/listar', {});
    }

    alterarDispositivo(command: AlterarDispositivoUsuarioSessaoCommand){
        return requestHelper.sendRequestPost<object>('usuario/interno/sessao/alterar-dispositivo', command, false);
    }


    encerrarUsuarioSessao(command: EncerrarUsuarioSessaoCommand) {
        return requestHelper.sendRequestPost<object>('usuario/interno/sessao/encerrar', command, true);
    }

}

export default new UsuarioService();