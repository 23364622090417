<script setup lang="ts">
import {useLayout} from '@/layout/composables/layout';
import AppConfigurator from "@/layout/header/AppConfigurator.vue";
import AppUserMenu from "@/layout/header/AppUserMenu.vue";
import {EStatusUsuario} from "@/type/usuario.type";
import ConfirmacaoEmailUsuario from "@/components/usuario/ConfirmacaoEmailUsuario.vue";

import {useAuthStore} from "@/stores/auth.store";
import {ref} from "vue";
import {useLayoutConfigurator} from "@/layout/header/layoutConfigurator";



const {onMenuToggle,  isDarkTheme} = useLayout();
const {toggleDarkMode,setAppScale,getAppScale} = useLayoutConfigurator();



const authStore = useAuthStore();
const appTitle = ref(import.meta.env.VITE_APP_TITLE);


</script>

<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo-container">
      <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
        <i class="pi pi-bars" style="font-size: 1.8rem"></i>
      </button>

      <div class="layout-topbar-logo ml-6">
        <img :src="'layout/images/logo.png'" alt="uCase" class="h-[3.5rem]">

        <span>{{ ((!appTitle || appTitle == '') ? '--' : appTitle) }}</span>
      </div>
    </div>

    <div class="layout-topbar-actions items-center">
      <div class="layout-config-menu">
        <button type="button" class="layout-topbar-action" @click="toggleDarkMode">
          <i :class="['pi', { 'pi-moon': isDarkTheme, 'pi-sun': !isDarkTheme }]"></i>
        </button>

        <div class="relative">
          <button
              v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }"
              type="button"
              class="layout-topbar-action layout-topbar-action-highlight"
          >
            <i class="pi pi-palette"></i>
          </button>
          <AppConfigurator/>
        </div>


          <button type="button" class="hidden md:block"
                  v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }"
          >

            <i class="pi  pi-window-maximize" style="font-size: 1rem; margin-right: 0.5rem;"></i>
          </button>
          <div class=" hidden absolute top-[4.5rem] right-[1.5rem] w-[12rem] p-4 bg-surface-0 dark:bg-surface-900 border border-surface rounded-border origin-top">
            <span class="text-sm text-muted-color font-semibold">Selecione a escala</span>
            <div class="flex  mt-2 items-center ">


              <i @click="setAppScale(0)" :class="(getAppScale == 0 ? 'pi pi-circle-fill' : 'pi pi-circle')"  style="font-size: 1rem" class="mx-1  cursor-pointer " v-tooltip.bottom="'10px'"  ></i>
              <i @click="setAppScale(1)" :class="(getAppScale == 1 ? 'pi pi-circle-fill' : 'pi pi-circle')"  style="font-size: 1.2rem" class="mx-1  cursor-pointer " v-tooltip.bottom="'12px'"></i>
              <i @click="setAppScale(2)" :class="(getAppScale == 2 ? 'pi pi-circle-fill' : 'pi pi-circle')"  style="font-size: 1.4rem" class="mx-1  cursor-pointer " v-tooltip.bottom="'14px - PADRÃO'"></i>
              <i @click="setAppScale(3)" :class="(getAppScale == 3 ? 'pi pi-circle-fill' : 'pi pi-circle')"  style="font-size: 1.6rem" class="mx-1  cursor-pointer " v-tooltip.bottom="'16px'"></i>
              <i @click="setAppScale(4)" :class="(getAppScale == 4 ? 'pi pi-circle-fill' : 'pi pi-circle')"  style="font-size: 1.8rem" class="mx-1  cursor-pointer " v-tooltip.bottom="'18px'"></i>
            </div>
          </div>


      </div>

      <AppUserMenu/>

      <ConfirmacaoEmailUsuario v-if="authStore.userStore.status == EStatusUsuario.Cadastrado"/>
    </div>
  </div>
</template>
